.content {
  &-item {
    margin-bottom: 20px;
    position: relative;
    &:last-child {
      margin: 0;
    }
    .download {
      position: absolute;
      bottom: 0;
      width: 100%;
      padding: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      box-sizing: border-box;
      a {
        color: light-neutral(0);
        background-color: #8c5e46;
        line-height: 40px;
        border-radius: 5px;
        margin: 0 10px;
        padding: 0 15px;
        box-shadow: 2px 2px 10px -5px dark-neutral(0);
        transition: all, .4s;
        &:hover {
          background-color: light-neutral(0);
          color: dark-neutral(0);
          transition: all, .4s;
        }
      }
    }
  }
}

@media #{$media-sm} {
  .content {
    &-item {
      .download {
        position: relative;
      }
    }
  }
}
