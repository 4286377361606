.card--article {
  border-radius: $border-radius;
  overflow: hidden;

  .card__category {
    position: absolute;
    top: 1rem;
    right: 1rem;
  }

  .card__title {
    font-size: 1.25rem;
  }

  .card__subtitle {
    font-size: 0.9375rem;
  }
}
