@import '~react-bnb-gallery/dist/style.css';

.gallery-modal--close {
  padding: 0;
}

.gallery-modal--close button.gallery-close {
  position: relative;
  font-size: 15px;
  width: 80px;
  height: 80px;
  padding: 0;
}

.gallery-modal--close button.gallery-close svg {
  display: none !important;
}

.gallery-modal--close button.gallery-close::before,
.gallery-modal--close button.gallery-close::after {
  position: absolute;
  top: 50%;
  left: 50%;
  content: '';
  width: 3px;
  height: 33px;
  background-color: #fff;
  display: block;
  border-radius: 3px;

}

.gallery-modal--close button.gallery-close::before {
  transform: translate(-2px, -50%) rotate(-45deg);
}

.gallery-modal--close button.gallery-close::after {
  transform: translate(-2px, -50%) rotate(45deg);
}

.gallery .gallery-control {
  font-size: 13px;
}

.gallery-figcaption .gallery-figcaption--thumbnails .caption-thumbnails ul.thumbnails-list li {
  margin: 0
}

.gallery-figcaption .gallery-figcaption--thumbnails .caption-thumbnails ul.thumbnails-list li button.thumbnail-button div.picture img.thumbnail {
  opacity: .4;
}

.gallery-figcaption .gallery-figcaption--thumbnails .caption-thumbnails ul.thumbnails-list li button.thumbnail-button {
  outline: none;
  border: 0;
}

.gallery-figcaption--info .photo-caption {
  font-size: 1.1rem;
}


.gallery-figcaption--info .photo-subcaption {
  font-size: .9rem;
  margin-top: 3px;
}
