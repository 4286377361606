@mixin appearance($appearance, $varname) {
  .button--standard-#{$appearance} {
    color: var(--#{$varname}500);
    background-color: var(--#{$varname}50);

    &:not([disabled]):hover {
      opacity: 0.8;
    }

    &:not([disabled]):focus {
      box-shadow: 0px 0px 0px 2px var(--#{$varname}100);
    }

    &:not([disabled]):active {
      opacity: 1;
      background-color: var(--#{$varname}75);
    }
  }

  .button--contained-#{$appearance} {
    color: #fff;
    background-color: var(--#{$varname}400);

    &:not([disabled]):hover {
      background-color: var(--#{$varname}300);
    }

    &:not([disabled]):focus {
      box-shadow: 0px 0px 0px 2px var(--#{$varname}100);
    }

    &:not([disabled]):active {
      background-color: var(--#{$varname}500);
    }
  }

  .button--outlined-#{$appearance} {
    color: var(--#{$varname}400);
    border: 1px solid var(--#{$varname}300);

    &:not([disabled]):hover,
    &:not([disabled]):focus {
      background-color: var(--#{$varname}50);
    }

    &:not([disabled]):active {
      color: var(--#{$varname}500);
      background-color: var(--#{$varname}75);
    }
  }

  .button--text-#{$appearance} {
    color: var(--#{$varname}400);

    &:not([disabled]):hover,
    &:not([disabled]):focus {
      background-color: var(--#{$varname}50);
    }

    &:not([disabled]):active {
      color: var(--#{$varname}500);
      background-color: var(--#{$varname}75);
    }
  }
}

@mixin size($type, $font-size, $height, $padding) {
  .button--size-#{$type} {
    font-size: $font-size;
    min-width: $height;
    min-height: $height;
    line-height: $height;
    padding-left: $padding;
    padding-right: $padding;
  }
}

@mixin shape($type, $border-radius) {
  .button--shape-#{$type} {
    border-radius: $border-radius;
  }
}

.button-group {
  display: inline-flex;
  align-items: center;
  margin: 0 -2px;

  .button {
    margin: 0 2px;
  }
}

.button {
  $button: &;
  align-items: center;
  appearance: none;
  background-color: transparent;
  border: 1px solid transparent;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-flex;
  font-weight: 500;
  justify-content: center;
  margin: 0;
  max-width: 100%;
  outline: 0;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: all 0.1s ease-out 0s;
  user-select: none;
  vertical-align: middle;

  &[disabled] {
    cursor: not-allowed;
    opacity: 0.65;
  }

  span {
    margin: 0 4px;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  a,
  a:hover,
  a:focus {
    color: inherit;
    text-decoration: none;
    outline: none;
  }

  &--state-loading > * {
    opacity: 0;
    transition: opacity 0.3s ease 0s;
  }

  &-loading {
    position: absolute;
    opacity: 1;
  }

  &-text {
    flex: 1;
  }
}

.button--standard-default {
  color: light-neutral(0);
  background-color: primary(300);

  &:not([disabled]):hover {
    background-color: primary(100);
    color: light-neutral(0);
  }

  &:not([disabled]):focus {
    box-shadow: 0px 0px 0px 2px primary(75);
  }

  &:not([disabled]):active {
    color: primary(500);
    background-color: primary(50);
  }
}

.button--contained-default {
  color: var(--N30);
  background-color: var(--N700);

  &:not([disabled]):hover {
    background-color: var(--N600);
  }

  &:not([disabled]):focus {
    box-shadow: 0px 0px 0px 2px var(--N100);
  }

  &:not([disabled]):active {
    background-color: var(--N800);
  }
}

.button--outlined-default {
  color: var(--N800);
  border: 1px solid var(--N40);

  &:not([disabled]):hover,
  &:not([disabled]):focus {
    background-color: var(--N40);
  }

  &:not([disabled]):active {
    background-color: var(--N40);
  }
}

.button--text-default {
  color: var(--N800);

  &:not([disabled]):hover,
  &:not([disabled]):focus {
    background-color: var(--N40);
  }

  &:not([disabled]):active {
    background-color: var(--N50);
  }
}

.button--icon {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

@include appearance(subtle, N);
@include appearance(error, R);
@include appearance(help, P);
@include appearance(info, B);
@include appearance(success, G);
@include appearance(warning, Y);

@include size(xlarge, 15px, 42px, 20px);
@include size(large, 14px, 38px, 16px);
@include size(medium, 14px, 34px, 12px);
@include size(small, 14px, 30px, 8px);
@include size(xsmall, 13px, 26px, 4px);
@include size(none, 12px, 16px, 0);

@include shape(circle, 50%);
@include shape(pill, 16px);
@include shape(square, 3px);
@include shape(none, 0);
