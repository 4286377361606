.section {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  padding: 90px 0;
  display: flex;
  align-items: center;
  position: relative;
  box-sizing: border-box;
  &--full {
    min-height: 100vh;
    height: max-content;
  }
  iframe {
    height: 400px;
    border: 3px solid primary(200) !important;
    border-radius: 50px;
  }
}
@media (max-width: 1200px) {
  .section {
    &--full {
      height: max-content;
    }
  }
}
@media screen and (min-device-width: 1200px) and (max-device-width: 1350px) {
  .section {
    &--full {
      height: max-content;
      min-height: 100vh;
    }
  }
}

@media #{$media-sm} {
  .section {
    padding: 25px 0;
  }
}
