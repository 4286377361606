#our-products {
  align-items: start;
}

.our-products {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  .slick {
    &-slider {
      height: inherit;
      .slick-list,
      .slick-track {
        height: inherit;
        .slick-slide {
          height: inherit;
          div {
            height: inherit;
            .image {
              width: 100%;
              height: 100%;
              img {
                object-fit: cover;
              }
            }
          }
        }
      }
    }
    &-navigation {
      position: absolute;
      left: 90px;
      bottom: 90px;
      color: primary(200);
      font-size: 35px;
      display: flex;
      align-items: center;
      z-index: 2;
      .number {
        margin: 0 10px;
        color: light-neutral(0);
        font-size: 20px;
      }
      button {
        background: transparent !important;
        border: none !important;
        box-shadow: none !important;
        color: primary(100);
        width: 50px;
        height: 50px;
        margin: 0 15px;
        .c-icon {
          line-height: 50px;
          font-size: 40px;
        }
        &:first-child {
          margin-left: 0;
        }
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
  .flexbox-slider {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: flex-end;
    width: 85%;
    height: 300px;
    visibility: hidden;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    padding: 0 15px;
    bottom: 90px;
    box-sizing: border-box;
    z-index: 1;
    .flexbox-slide {
      transition-property: all;
      transition-duration: 0.3s;
      transition-timing-function: linear;
      transition-delay: 0s;
      width: 160px;
      height: 100%;
      position: relative;
      overflow: hidden;
      border-radius: 50px;
      box-shadow: 0 0 15px -3px transparent;
      border: 1px solid transparent;
      z-index: 2;
      cursor: pointer;
      visibility: visible;
      background: transparent;
      margin-right: -80px;
      .title {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        padding: 15px;
        text-align: center;
        box-sizing: border-box;
        color: light-neutral(0);
        font-weight: 200;
        transition: all, 0.4s;
        &--bottom {
          bottom: -100px;
        }
        &--left {
          transform: rotate(90deg);
          left: -110px;
          bottom: 110px;
          height: 80px;
          width: 300px;
          line-height: 40px;
        }
        &--right {
          transform: rotate(90deg);
          left: auto;
          right: -110px;
          bottom: 110px;
          height: 80px;
          width: 300px;
          line-height: 40px;
        }
      }
      &:last-child {
        margin-right: 0;
      }
      .image {
        width: 100%;
        height: 100%;
        filter: blur(4px);
        img {
          position: absolute;
          top: 50%;
          left: 50%;
          height: auto;
          width: auto;
          min-width: 100%;
          min-height: 100%;
          -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
          z-index: 1;
          object-fit: cover;
        }
      }
    }
    .flexbox-slide--active {
      -ms-flex-negative: 0;
      flex-shrink: 0;
      width: 300px;
      box-shadow: 0 0 50px -15px primary(200);
      border-color: primary(200);
      z-index: 9999 !important;
      .title {
        &--left {
          left: -300px;
          transition: all, 0.4s;
        }
        &--right {
          right: -300px;
          transition: all, 0.4s;
        }
        &--bottom {
          bottom: 0;
          transition: all, 0.4s;
        }
      }
      .image {
        filter: blur(0);
      }
    }
    .flexbox-slide--active + .flexbox-slide,
    .flexbox-slide--active ~ .flexbox-slide {
      z-index: 3;
      /* Aktif öğenin hemen sonrasındaki ve öncesindeki öğeleri z-index: 3 olarak ayarlar */
    }
  }
}

@media screen and (min-width: 1200px) and (max-width: 1350px) {
  #our-products {
    min-height: 800px;
  }
}
@media (max-width: 1200px) {
  #our-products {
    background-color: gray;
    min-height: 750px;
  }
}

@media #{$media-lg} {
  .our-products {
    .slick {
      &-slider {
        .slick-list,
        .slick-track {
          .slick-slide {
            div {
              .image {
                background-color: primary(500);
                img {
                  filter: blur(10px);
                }
              }
            }
          }
        }
      }
      &-navigation {
        left: 50%;
        right: auto;
        transform: translateX(-50%);
        bottom: 25px;
        background-color: primary(500);
        border-radius: 50px !important;
        box-shadow: 0 0 15px -3px transparent;
        border: 1px solid primary(200);
      }
    }
    .flexbox-slider {
      bottom: 115px;
      justify-content: space-between;
    }
  }
}

@media #{$media-sm} {
  #our-products {
    padding: 25px 0;
    min-height: 700px;
  }
  .our-products {
    .slick {
      &-navigation {
        bottom: 50px;
      }
    }
    .flexbox-slider {
      width: 100%;
      bottom: 140px;
      padding: 0 35px;
    }
  }
}
