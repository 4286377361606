.pagination {
  text-align: center;

  &:not(:first-child) {
    margin-top: 1.5rem;
  }

  &:not(:last-child) {
    margin-bottom: 1.5rem;
  }

  .button {
    margin: 0 2px;
  }

  &-ellipsis {
    width: 34px;
    margin: 0 1px;
    color: neutral(200);
  }
}
