@mixin contained($appearance, $color, $background-color) {
  .alert--contained-#{$appearance} {
    color: $color;
    background-color: $background-color;
  }
}

@mixin outlined($appearance, $color, $border-color) {
  .alert--outlined-#{$appearance} {
    color: $color;
    border-color: $border-color;
  }
}

@mixin standard($appearance, $color, $background-color) {
  .alert--standard-#{$appearance} {
    color: $color;
    background-color: $background-color;
  }
}

.alert {
  position: relative;
  display: flex;
  padding: 1rem;
  gap: 1rem;
  box-sizing: border-box;
  border: 1px solid transparent;
  border-radius: $border-radius;

  &:not(:first-child) {
    margin-top: 1rem;
  }

  &:not(:last-child) {
    margin-bottom: 1rem;
  }

  &-title {
    font-size: 1rem;
    line-height: 1.25;
    font-weight: 600;
    margin: 0 0 6px 0;
  }

  &-content {
    font-size: 14px;
    flex: 1;

    p {
      font-size: 1em;
      margin: 0;

      &:not(:first-child) {
        margin-top: 4px;
      }

      &:not(:last-child) {
        margin-bottom: 4px;
      }
    }
  }
}

@include contained(default, neutral(30), neutral(700));
@include contained(error, light-neutral(0), red(400));
@include contained(help, light-neutral(0), purple(400));
@include contained(info, light-neutral(0), blue(400));
@include contained(success, light-neutral(0), green(400));
@include contained(warning, light-neutral(0), yellow(400));

@include outlined(default, neutral(600), neutral(40));
@include outlined(error, red(400), red(300));
@include outlined(help, purple(400), purple(300));
@include outlined(info, blue(400), blue(300));
@include outlined(success, green(400), green(300));
@include outlined(warning, yellow(400), yellow(300));

@include standard(default, neutral(700), neutral(30));
@include standard(error, red(500), red(50));
@include standard(help, purple(500), purple(50));
@include standard(info, blue(500), blue(50));
@include standard(success, green(500), green(50));
@include standard(warning, yellow(500), yellow(50));
