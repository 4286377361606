.c-modal {
  $modal: &;
  position: relative;
  max-width: 100%;
  height: auto;
  min-height: 0px;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  margin: 3rem auto;
  padding: 1.5rem;
  z-index: 200;
  box-sizing: border-box;
  border-radius: border-radius;

  &--width {
    &-narrow {
      width: 420px;
    }

    &-medium {
      width: 600px;
    }

    &-wide {
      width: 800px;
    }

    &-fullscreen {
      width: 100%;
      height: 100%;
      height: calc(100%);
      border-radius: 0;
      padding: 2rem;
      margin: 0;
    }

    &-extended {
      width: 90%;
    }
  }

  &--scroll-behavior {
    &-inside {
      max-height: calc(100% - 6rem);

      .scrollbar {
        flex: 1 1 auto;
        padding-right: 0.75rem;
      }
    }
  }

  &-portal {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100vw;
    height: 100vh;
    height: -webkit-fill-available;
    height: calc(100%);
    overflow-x: hidden;
    overflow-y: auto;
    z-index: 9990;
  }

  &-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: neutral(900);
    z-index: 100;
    opacity: 0.75;
  }

  &-header {
    display: flex;
    width: 100%;
    margin-bottom: 1.25rem;

    #{$modal}-icon {
      margin-right: 0.75rem;
      flex: 0 0 auto;
    }

    #{$modal}-heading {
      margin-top: 0;
    }
  }

  &-content {
    flex: 1 1 auto;
  }

  &-footer {
    padding-top: 1.25rem;
    margin-top: 1.25rem;
    border-top: 1px solid neutral(30);
  }
}
