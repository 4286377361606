.header {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 9;
  padding: 15px 0;
  transition: all, 1s ease-in-out;
  &::before {
    content: '';
    background-color: rgba(primary-rgb(200), 0.6);
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    border-bottom-left-radius: 175px;
    border-bottom-right-radius: 175px;
    box-shadow: 0px 5px 35px -15px dark-neutral(0);
    z-index: -1;
  }
  .col {
    padding: 0 15px;
    .logo {
      .image {
        width: max-content;
        height: 70px;
      }
    }
    .menu {
      ul {
        list-style-type: none;
        padding: 0;
        margin: 0;
        display: flex;
        justify-content: space-between;
        width: 100%;
        li {
          margin: 0 15px;
          .menu-item {
            color: light-neutral(0);
            font-size: 16px;
            opacity: 0.4;
            white-space: nowrap;
            &.active {
              opacity: 1;
            }
          }
          &.active {
             a {
              opacity: 1;
             }
          }
        }
      }
    }
  }
  .header-action {
    display: flex;
    .menu-button {
      border-radius: 100%;
      background-color: transparent;
      border: 1px solid light-neutral(0);
      width: 36px;
      height: 36px;
      color: light-neutral(0);
      margin-right: 25px;
    }
    .language {
      &-list {
        display: flex;
        list-style-type: none;
        padding: 0;
        margin: 0;
        border-radius: 25px;
        overflow: hidden;
        border: 1px solid light-neutral(0);
        li {
          margin: 0;
          padding: 0;
          button {
            text-transform: uppercase;
            border: none;
            border-radius: 0;
            background: primary(100);
            transition: all, 0.4s;
            color: rgba(light-neutral-rgb(0), 0.4);
            &:focus,
            &:active {
              box-shadow: none;
            }
            &:hover {
              background: primary(200);
              color: rgba(light-neutral-rgb(0), 1);
              transition: all, 0.4s;
            }
            &.current {
              color: rgba(light-neutral-rgb(0), 1);
            }
          }
          &:first-child {
            button {
              border-right: 1px solid rgba(light-neutral-rgb(0), 0.5);
            }
          }
        }
      }
    }
  }
  &--disable {
    top: -100%;
    transition: all, 2s ease-out;
  }
}

.panel {
  position: fixed;
  width: 80%;
  height: 100%;
  right: 0;
  top: 0;
  z-index: 999;
  transition: all, .4s ease-in;
  transition-delay: 0.2s;
  &-logo {
    .image {
      width: 80px;
    }
  }
  .header-action {
    display: flex;
    .menu-button {
      border-radius: 100%;
      background-color: transparent;
      border: 1px solid light-neutral(0);
      width: 36px;
      height: 36px;
      color: light-neutral(0);
      margin-right: 25px;
    }
    .language {
      &-list {
        display: flex;
        list-style-type: none;
        padding: 0;
        margin: 0;
        border-radius: 25px;
        overflow: hidden;
        border: 1px solid light-neutral(0);
        li {
          margin: 0;
          padding: 0;
          button {
            text-transform: uppercase;
            border: none;
            border-radius: 0;
            background: primary(100);
            transition: all, 0.4s;
            color: rgba(light-neutral-rgb(0), 0.4);
            &:focus,
            &:active {
              box-shadow: none;
            }
            &:hover {
              background: primary(200);
              color: rgba(light-neutral-rgb(0), 1);
              transition: all, 0.4s;
            }
            &.current {
              color: rgba(light-neutral-rgb(0), 1);
            }
          }
          &:first-child {
            button {
              border-right: 1px solid rgba(light-neutral-rgb(0), 0.5);
            }
          }
        }
      }
    }
  }
  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    li {
      margin: 0;
      .menu-item {
        color: light-neutral(0);
        font-size: 16px;
        opacity: 0.4;
        white-space: nowrap;
        padding: 15px 35px;
        display: block;
        &.active {
          opacity: 1;
        }
      }
      &.active {
         a {
          opacity: 1;
         }
      }
    }
  }
  &::before {
    content: "";
    background-color: rgba(primary-rgb(200), 0.6);
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    box-shadow: 0px 5px 35px -15px dark-neutral(0);
    z-index: -1;
  }
  &--hide {
    right: -80%;
    transition: all, .4s ease-out;
  }
}

@media #{$media-sm} {
  .header {
    .col {
      .logo {
        .image {
          height: 40px;
        }
      }
      .languages {
        .language-list {
          &-item {
            button {
              font-size: 10px;
              line-height: 25px;
              min-height: auto;
              padding: 0 5px;
            }
          }
        }
      }
    }
    &::before {
      border-bottom-left-radius: 50px;
      border-bottom-right-radius: 50px;
    }
  }
}

