#catalogs {
  padding: 150px 0;
  text-align: center;
}

.catalogs {
  display: flex;
  justify-content: center;
  margin-top: 75px;
  .catalog {
    display: flex;
    flex-direction: column;
    padding: 0 60px;
    .title {
      color: primary(100);
      font-size: 30px;
      margin-top: 20px;
      line-height: 35px;
      font-weight: bold;
      &.bottom {
        font-weight: 400;
        color: dark-neutral(0);
      }
    }
    .description {
      color: dark-neutral(0);
      font-size: 15px;
      margin-bottom: 20px;
    }
    .link-wrapper {
      display: flex;
      margin-bottom: 10px;
      justify-content: space-around;
      a {
        // width: 25%;
        font-size: 20px;
        color: light-neutral(0);
        text-transform: uppercase;
        background-color: primary(200);
        padding: 5px 15px;
        border-radius: 5px;
        // text-align: center;
        // &:last-child {
        //   text-align: right;
        // }
        // &:first-child {
        //   text-align: left;
        // }
      }
    }
    .pdf-link {
      position: relative;
      border-radius: 30px;
      overflow: hidden;
      &::before {
        content: "";
        width: 100%;
        height: 100%;
        background-color: rgba(dark-neutral-rgb(0), 0.8);
        position: absolute;
        z-index: 1;
        left: 0;
        top: 0;
        opacity: 0;
        transition: all, .4s;
      }
      .c-icon {
        position: absolute;
        top: 0;
        left: 50%;
        z-index: 2;
        font-size: 50px;
        transform: translateX(-50%) translateY(-50%);
        opacity: 0;
        transition: all, .4s;
        transition-delay: .4s;
        &::before {
          color: #FFF;
          text-shadow: 1px 1px 1px dark-neutral(0);
        }
      }
    }
    .image {
      width: 100%;
    }
    &:hover {
      .pdf-link {
        &::before {
          opacity: 1;
          transition: all, .4s;
        }
        .c-icon {
          top: 50%;
          opacity: 1;
          transition: all, .4s;
          transition-delay: .4s;
        }
      }
    }
  }
}

@media #{$media-lg} {
  .catalogs {
    margin: 0 -25px;
    .catalog {
      padding: 25px;
      .title {
        font-size: 20px;
        margin-bottom: 15px;
      }
      .description {
        font-size: 12px;
      }
      .link-wrapper {
        a {
          font-size: 16px;
        }
      }
    }
  }
}
@media #{$media-sm} {
  .catalogs {
    .catalog {
      .title {
        font-size: 23pt;
        margin-bottom: 15px;
      }
      .description {
        font-size: 14pt;
      }
      .link-wrapper {
        a {
          font-size: 11pt;
        }
      }
    }
  }
}
@media #{$media-xs} {
  .catalogs {
    .catalog {
      .title {
        font-size: 23pt;
        margin-bottom: 15px;
      }
      .description {
        font-size: 14pt;
      }
      .link-wrapper {
        a {
          font-size: 11pt;
        }
      }
    }
  }
}
