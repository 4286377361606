@keyframes rotate {
  to {
    transform: rotate(360deg);
  }
}

.spinner {
  display: inline-flex;
  justify-content: center;
  align-items: center;

  svg {
    transform-origin: center;
    animation: rotate 0.86s infinite;
    animation-timing-function: cubic-bezier(0.4, 0.15, 0.6, 0.85);
    fill: none;
    stroke: neutral(500);
    stroke-width: 1.5;
    stroke-linecap: round;
    stroke-dasharray: 60;
    stroke-dashoffset: 50;
  }
}

.spinner-cover {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 900;
  display: block;

  &:before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    opacity: 0.1;
    background-color: neutral(900);
  }

  &-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  span {
    animation-name: spin;
    animation-duration: 700ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }
}
