.banner {
  position: relative;
  height: 100vh;
  background-color: primary(500);
  &-body {
    width: 100%;
    height: 100%;
    position: relative;
    &-image {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      opacity: 1;
      transition: all, 0.4s;
      .image {
        width: 100%;
        height: 100%;
        img {
          width: 100%;
          object-fit: cover;
        }
      }
      &.hidden {
        opacity: 0;
        pointer-events: none;
        transition: all, 0.4s;
      }
    }
    &-video {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      opacity: 1;
      transition: all, 0.4s;
      video {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    &-text {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      display: flex;
      align-items: flex-end;
      padding: 139px 0 80px 0;
      box-sizing: border-box;
      .desc {
        border-left: 3px solid primary(200);
        color: light-neutral(0);
        padding: 30px;
        font-size: 14px;
        font-weight: 200;
        line-height: 27px;
        .title {
          font-size: 60px;
          line-height: 55px;
          font-weight: bold;
          margin-bottom: 70px;
        }
      }
    }
  }
}

@media (max-width: 1200px) {
  .banner {
    &-body {
      &-text {
        padding: 0 !important;
      }
      .desc {
        flex-basis: 100% !important;
        max-width: 100% !important;
      }
      .scroll-down {
        position: relative;
        text-align: center;
        width: 100%;
        margin-bottom: 100px;
        span {
          display: flex;
          flex-direction: column;
          align-items: center;
        }
      }
    }
  }
}

@media #{$media-sm} {
  .banner {
    &-body {
      .scroll-down {
        margin-bottom: 25px;
      }
    }
  }
}
