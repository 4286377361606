.footer {
  position: relative;
  overflow: hidden;
  margin-top: 50px;
  &-copyright {
    background-color: dark-neutral(10);
    color: light-neutral(0);
    box-shadow: inset 0 1px 8px rgba(dark-neutral-rgb(0), 0.25);
    position: relative;
    z-index: 2;
  }
  &-bottom {
    background-color: primary(200);
    box-shadow: inset 0 14px 39px rgba(dark-neutral-rgb(0), .5);
    padding: 60px 0;
    opacity: 0;
    transform: translateX(0px) translateY(-300px) translateZ(0px);
    position: relative;
    z-index: 1;
  }
  &--active {
    .footer-bottom {
      opacity: 1;
      transform: translateX(0px) translateY(0) translateZ(0px);
      transition: opacity 750ms ease 0s, transform 1000ms ease 0s;
    }
  }
}

@media #{$media-sm} {
  .footer {
    margin: 0;
  }
}
