@mixin appearance($name, $color) {
  .divider--appearance-#{$name} {
    color: $color;
  }
}

@mixin space($name, $margin) {
  .divider--space-#{$name} {
    margin: $margin;
  }
}

.divider {
  margin: 0px;
  flex-shrink: 0;
  display: flex;
  white-space: nowrap;
  text-align: center;
  border: 0px;

  &:before,
  &:after {
    position: relative;
    content: '';
    width: 100%;
    border-top-width: 1px;
    border-top-style: solid;
    border-top-color: inherit;
    top: 50%;
    transform: translateY(50%);
  }
}

@include appearance(default, neutral(40));
@include appearance(error, red(100));
@include appearance(help, purple(100));
@include appearance(info, blue(100));
@include appearance(success, green(100));
@include appearance(warning, yellow(100));

@include space(xsmall, $margin-xs 0);
@include space(small, $margin-sm 0);
@include space(medium, $margin-md 0);
@include space(large, $margin-lg 0);
@include space(xlarge, $margin-xl 0);
