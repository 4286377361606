.section-head {
  margin-bottom: 35px;
  .title {
    color: primary(200);
    font-size: 40px;
    margin-bottom: 35px;
  }
  .desc {
    padding: 0 30px;
    font-size: 14px;
    font-weight: 200;
  }
  &--left {
    text-align: left;
    .desc {
      border-left: 3px solid primary(200);
    }
  }
  &--right {
    text-align: right;
    .desc {
      border-right: 3px solid primary(200);
    }
  }
  &--reverse {
    .desc {
      color: light-neutral(0);
    }
  }
}

@media #{$media-sm} {
  .section-head {
    .title {
      font-size: 30px;
    }
  }
}
