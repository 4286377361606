.document {
  padding: $padding-xl 0;

  &-header {
    margin-bottom: $margin-xl;

    &:after {
      content: '';
      border-radius: $border-radius;
      display: block;
      border-bottom: 8px solid primary(50);
      margin-top: $margin-xl;
    }

    h1 {
      font-weight: 500;
    }

    p {
      color: neutral(400);
      max-width: 75%;
      font-size: 1em;
      line-height: 1.65em;
    }
  }

  .code {
    display: block;
    font-size: 0.875em;
    overflow-x: auto;
    padding: $padding;
    margin: 0;
    color: neutral(500);
    background-color: neutral(20);
  }

  .color {
    font-family: $font-family;
    border-radius: 4px;
    overflow: hidden;

    &-swatch {
      height: 120px;
    }

    &-content {
      padding: 30px;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      background-color: neutral(20);
    }

    &-content > div:not(:first-child) {
      margin-top: 16px;
    }

    &-content h4 {
      font-size: 12px;
      margin: 0;
      text-transform: uppercase;
    }

    &-content p {
      font-size: 14px;
      margin: 0;
    }

    &-name {
      width: 100%;
    }
  }

  .example {
    position: relative;
    padding: $padding-lg;
    margin-top: $margin-lg;
    border-radius: $border-radius;
    border: 1px solid neutral(30);
    background-image: linear-gradient(45deg, neutral(20) 25%, transparent 25%),
      linear-gradient(135deg, neutral(20) 25%, transparent 25%),
      linear-gradient(45deg, transparent 75%, neutral(20) 75%),
      linear-gradient(135deg, transparent 75%, neutral(20) 75%);
    background-size: 20px 20px;
    background-position: 0px 0px, 10px 0px, 10px -10px, 0px 10px;

    select {
      position: absolute;
      min-width: 150px;
      right: 0;
      padding: $padding-xs;
      line-height: 1;
      font-size: 14px;
      font-weight: 600;
      bottom: 100%;
      border: 1px solid neutral(40);
      background-color: neutral(20);
      border-radius: $border-radius $border-radius 0 0 ;
      text-transform: capitalize;

      &:focus {
        outline-offset: 0;
      }
    }
  }

  .icon-box {
    margin: 2px;
    padding: 12px;
    vertical-align: middle;
    display: inline-flex;
    background-color: neutral(30);
    border-radius: 2px;
  }

  .masonry-box {
    color: blue(500);
    font-size: 2rem;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: blue(50);
    border-radius: $border-radius;
  }

  .placeholder {
    color: blue(500);
    font-size: 1rem;
    font-weight: 600;
    padding: .75rem;
    text-align: center;
    background-color: blue(50);
  }
}

.document-card {
  $target: &;
  display: block;
  min-height: 100%;
  background: neutral(0);
  border: 1px solid neutral(30);
  box-shadow: 0 2px 4px rgba(9, 30, 66, 0.1);
  overflow: hidden;
  border-radius: $border-radius;
  box-sizing: border-box;

  &:hover {
    box-shadow: 0 8px 16px -3px rgba(9, 30, 66, 0.2);
  }

  h3 {
    font-size: 1.5em;
    font-weight: 500;
    margin: 0;
  }

  p:not([class]) {
    color: neutral(400);
  }

  &--presentation {
    display: flex;
    flex-direction: column;

    #{$target}-figure {
      width: 100%;
      background-color: neutral(10);
    }

    #{$target}-image {
      width: 100%;
      height: auto;
    }

    #{$target}-content {
      padding: $padding-xl;
    }
  }

  &--component {
    padding: $padding-lg;

    .image {
      width: 100%;
      margin-bottom: $margin;
    }
  }

  &--foundation {
    padding: $padding-xl;
  }
}
