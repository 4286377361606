.production-list {
  .item {
    position: relative;
    overflow: hidden;
    border-radius: 50px;
    &-thumbnail {
      position: relative;
      .image {
        width: 100%;
      }
    }
    &-detail {
      position: absolute;
      bottom: 0;
      left: 0;
      padding: 25px 40px;
      color: light-neutral(0);
      z-index: 1;
      &::before {
        content: '';
        width: 200%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        z-index: -1;
        background: rgb(primary-rgb(500));
        background: linear-gradient(
          90deg,
          rgba(primary-rgb(500), 0.58) 0%,
          rgba(primary-rgb(200), 0.58) 100%
        );
        -webkit-backdrop-filter: blur(10px);
        backdrop-filter: blur(10px);
      }
      .title {
        font-size: 25px;
        font-weight: bold;
        margin-bottom: 15px;
        line-height: 25px;
      }
      .desc {
        border-left: 3px solid light-neutral(0);
        padding: 0 20px;
        font-weight: 200;
        font-size: 14px;
      }
    }
  }
}
@media (max-width: 550px) {
  .production-list {
    .item {
      &-detail {
        padding: 25px 20px;
        .title {
          font-size: 14px;
        }
        .desc {
          font-size: 10px;
        }
      }
    }
  }
}
