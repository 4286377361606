@include theme(dark) {
  --N900: #{dark-neutral(900)};
  --N800: #{dark-neutral(800)};
  --N700: #{dark-neutral(700)};
  --N600: #{dark-neutral(600)};
  --N500: #{dark-neutral(500)};
  --N400: #{dark-neutral(400)};
  --N300: #{dark-neutral(300)};
  --N200: #{dark-neutral(200)};
  --N100: #{dark-neutral(100)};
  --N90: #{dark-neutral(90)};
  --N80: #{dark-neutral(80)};
  --N70: #{dark-neutral(70)};
  --N60: #{dark-neutral(60)};
  --N50: #{dark-neutral(50)};
  --N40: #{dark-neutral(40)};
  --N30: #{dark-neutral(30)};
  --N20: #{dark-neutral(20)};
  --N10: #{dark-neutral(10)};
  --N0: #{dark-neutral(0)};
}

@include theme(dark) {
  --primary-50: #feece5;
  --primary-75: #f9cebd;
  --primary-100: #ffb99d;
  --primary-300: #ff672a;
  --primary-400: #f34800;
  --primary-500: #bd3700;
}
