.card--standard {
  border-radius: $border-radius;

  .card__image {
    border-radius: $border-radius $border-radius 0 0;
  }

  .card__actions {
    margin: 0 -12px;
  }
}
