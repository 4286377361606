.scroll-down {
  position: absolute;
  right: 0;
  bottom: 0;
  text-align: right;
  background: transparent !important;
  box-shadow: none !important;
  color: light-neutral(0) !important;
  .c-icon {
    &::before {
      font-size: 50px;
      width: 50px;
      height: 50px;
      line-height: 50px;
    }
  }
  span {
    display: block;
    width: 120px;
    font-size: 20px;
    line-height: 27px;
    font-weight: 400;
  }
}

@keyframes down-up {
  0% {
    bottom: 35px;
  }
  50% {
    bottom: 50px;
  }
  100% {
    bottom: 35px;
  }
}
@media (max-width: 1210px) {
  .scroll-down {
  }
}
