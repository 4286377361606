.contact-form {
  .col {
    span {
      padding: 0 15px;
      margin-bottom: 10px;
      display: block;
    }
    div {
      position: relative;
      border-radius: 25px;
      overflow: hidden;
      .error-message {
        background-color: red;
        color: #fff;
        font-size: 11px;
        padding: 0 15px;
        position: absolute;
        z-index: 1;
        top: 0px;
        opacity: 0;
        right: -100%;
        line-height: 30px;
        height: 0;
        overflow: hidden;
        border-top-right-radius: 25px;
        border-bottom-left-radius: 25px;
        box-sizing: border-box;
        pointer-events: none;
        transition: all, 0.4s;
        &--active {
          opacity: 1;
          right: 0;
          height: 30px;
          transition: all, 0.4s;
        }
      }
      input,
      textarea {
        width: 100%;
        padding: 15px;
        border-radius: 25px;
        box-sizing: border-box;
        resize: none;
        border: 1px solid primary(200) !important;
        box-shadow: none !important;
        outline: none !important;
        font-weight: 200;
      }
      textarea {
        height: 130px;
      }
    }
    button {
      margin: 0;
      background-color: primary(200);
      span {
        margin: 0;
      }
    }
  }
}

.alert-message {
  padding: 15px;
  background-color: primary(200);
  border-radius: 5px;
  color: #fff;
  opacity: 0;
  position: relative;
  top: -25px;
  transition: all, 0.4s;
  background-color: light-neutral(300);
  &--error {
    background-color: red;
  }
  &--success {
    background-color: green;
  }
  &--active {
    top: 0;
    opacity: 1;
    transition: all, 0.4s;
  }
}
